<template>
  <v-text-field
    v-model="inputVal"
    type="text"
    :label="field.name"
    :rules="rules[field.key]"
    :value="value?value:''"
  ></v-text-field>
</template>

<script>
import i18n from '@/plugins/i18n'
export default {
  props: ["callbackInput", "field","value"],
  data: () => ({
   rules: {
      schoolName: [v => !!v || i18n.t("rules.nameSchool")],
      classroomName: [v => !!v || i18n.t("rules.nameClassroom")],
       eventName: [v => !!v || i18n.t("rules.nameEvent")],
    },
  }),
 computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods:{
    
  },
};
</script>

<style>
</style>