<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>{{$t("view.PageBackendSchool.quick_setup_title")}}</v-card-title>
        <v-card-text>
          <span v-for="(field, i) in inputs" :key="i">
            <template>
              <FormInput
                :value="form[field.key]"
                :callbackInput="getInput"
                :field="field"
                @input="form[field.key] = $event"
              />
            </template>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)">{{$t("action.cancel")}} </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            {{$t("action.confirm")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- EOC -->
  </div>
</template>


<script>
import moment from "moment";
import FormInput from "@/components/Moderator/School/Add/FormInput";
import i18n from '@/plugins/i18n'
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    form: {
      schoolYear:9999,
      classroomName: "教师组",
      classroomWalkin: true,
      classroomSyllabusKey: "s010",
      eventName: "教师试玩",
      gameId:1,
      syllabusId:1,
      eventStart: moment().toISOString(),
      eventEnd: moment().add(1, "months").toISOString(),
      eventCheckpoint: [
        {
          chapterId: 1,
          questionKey: "s010_c010_q010",
        },
        {
          chapterId: 2,
          questionKey: "s010_c020_q040",
        },
        {
          chapterId: 3,
          questionKey: "s010_c030_q010",
        },
      ],
    },
    inputs: [
      {
        key: "schoolName",
        name: i18n.t("model.prop.nameSchool"),
      },
      {
        key: "classroomName",
        name: i18n.t("model.prop.nameClassroom"),
      },
      {
        key: "eventName",
        name: i18n.t("model.prop.nameEvent"),
      },
    ],
  }),

  components: {
    FormInput,
  },
  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    getInput(value) {
      console.log(value);
      this.form.eventId = value;
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>
